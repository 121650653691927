@charset "utf-8";

/* Roboto font from Google */
@font-face
{
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Roboto Regular'), url('/assets/fonts/roboto-regular.woff2') format('woff2');
}

@font-face
{
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: local('Roboto Italic'), url('/assets/fonts/roboto-italic.woff2') format('woff2');
}

@font-face
{
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	font-display: swap;
	src: local('Roboto Bold'), url('/assets/fonts/roboto-bold.woff2') format('woff2');
}

@font-face
{
	font-family: 'Roboto';
	font-style: italic;
	font-weight: bold;
	font-display: swap;
	src: local('Roboto Bold Italic'), url('/assets/fonts/roboto-bold-italic.woff2') format('woff2');
}

:root
{
	--typography__default: 'Roboto', sans-serif;
}