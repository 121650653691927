@charset "utf-8";

.ntSaveForms {
    width: 100%;
    font-size: 15px;
    display: block;
    padding: 5px 0;
    color: darkred;
    background: #fefefe;
    border: 1px solid #ccc;
    margin: 0 0 10px 0;
}

.msg_out {
    font-style: italic;
    font-size: 18px;
    padding: 80px 0px 80px 0px;
    width: 300px;
    text-align: center;
    line-height: 25px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block
}

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden] {
    display: none
}

html {
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

html,
button,
input,
select,
textarea {
    font-family: var(--typography__default);
}

body {
    margin: 0;
    overflow-x: hidden
}

a:focus {
    outline: thin dotted
}

a:active,
a:hover {
    outline: 0
}

h1 {
    font-size: 36px;
    margin: 0 0 20px;
    font-weight: 700;
    color: #000;
    line-height: 1em
}

h2 {
    margin: 0 0 20px;
    font-weight: 700;
    font-size: 25px;
    color: #212121;
    line-height: 1em
}

h3 {
    margin: 0 0 15px;
    font-weight: 700;
    font-size: 24px;
    color: #212121;
    line-height: 1em
}

h4 {
    margin: 0 0 15px;
    font-weight: 700;
    font-size: 18px;
    color: #212121
}

h5 {
    margin: 0 0 15px;
    font-size: 16px;
    font-weight: 700;
    color: #212121
}

h6 {
    font-size: 16px;
    margin: 0 0 15px;
    color: #828282;
    font-weight: 700
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
strong {
    font-weight: 700
}

blockquote {
    margin: 1em 40px
}

dfn {
    font-style: italic
}

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0
}

mark {
    background: #ff0;
    color: #000
}

p,
pre {
    margin: 1em 0
}

code,
kbd,
pre,
samp {
    font-family: var(--typography__default);
    font-size: 1em
}

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word
}

q {
    quotes: none
}

q:before,
q:after {
    content: '';
    content: none
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

dl,
menu,
ol,
ul {
    margin: 0
}

dd {
    margin: 0 0 0 20px
}

menu,
ol,
ul {
    padding: 0 0 10px 20px;
    margin-left: 20px;
}

li {
    margin: 0 0 10px
}

nav ul,
nav ol {
    list-style: none;
    list-style-image: none
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 0
}

form {
    margin: 0
}

th,
td,
tr {
    padding: 5px;
    border: solid;
    border-width: 1px 1px;
}

fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
}

legend {
    border: 0;
    padding: 0;
    white-space: normal;
    *margin-left: -7px
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle
}

button,
input {
    line-height: normal
}

button,
select {
    text-transform: none
}

button,
html input[type="button"],
/* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
    *overflow: visible
}

button[disabled],
html input[disabled] {
    cursor: default
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    *height: 13px;
    *width: 13px
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto;
    vertical-align: top
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid;

}

html,
button,
input,
select,
textarea {
    color: #222
}

html {
    font-size: 1em;
    line-height: 1.4
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none
}

::selection {
    background: #b3d4fc;
    text-shadow: none
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0
}

audio,
canvas,
img,
video {
    vertical-align: middle
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0
}

textarea {
    resize: vertical
}

.browsehappy {
    margin: .2em 0;
    background: #ccc;
    color: #000;
    padding: .2em 0
}

.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    *text-indent: -9999px
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%
}

.hidden,
.formfield2 {
    display: none !important;
    visibility: hidden
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto
}

.invisible {
    visibility: hidden
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table
}

.clearfix:after {
    clear: both
}

.clearfix {
    *zoom: 1
}

@media print {
    * {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important
    }

    a,
    a:visited {
        text-decoration: underline
    }

    a[href]:after {
        content: " (" attr(href) ")"
    }

    abbr[title]:after {
        content: " (" attr(title) ")"
    }

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: ""
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    tr,
    img {
        page-break-inside: avoid
    }

    img {
        max-width: 100% !important
    }

    @page {
        margin: .5cm
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }
}

.up-text {
    font-size: 12px;
    bottom: 0px;
    position: relative;
    float: left;
    display: block;
    width: 70px;
    text-align: center;
    margin-top: 60px;
}

#toTop {
    opacity: .8;
    width: 70px;
    height: 70px;
    background: url('/assets/images/up.png') no-repeat;
    position: fixed;
    bottom: 80px;
    right: 85px;
    cursor: pointer;
    display: none;
    z-index: 777;
}

.tinymce_body {
    margin: 0;
    padding: 0 10px;
    text-align: left;
    background: #fff;
    min-width: inherit;
    min-height: inherit;
    max-width: none;
    max-height: none
}

.error {
    color: #c66
}

.compare-wrapper {
    max-width: 100%;
    overflow: auto
}

.compare-tbl td {
    padding: 7px 5px
}

.compare-tbl tr:hover td {
    background: #f2ede9
}

.compare-tbl tr:first-child:hover td {
    background: none
}

.compare-tbl .products-card {
    float: none
}

.photo_prod_item {
    width: 33%;
    float: left;
    height: 215px;
    overflow: hidden;
    text-align: center
}

.mod_photo_tbl {
    width: 90%
}

.mod_photo_tbl td {
    width: 33%;
    padding: 25px 1px;
    font-size: 9pt;
    text-align: center;
    vertical-align: top
}

.mod_photo_tbl img {
    margin: 0 0 5px
}

.mod_photo_img>a {
    display: inline-block
}

#colorbox,
#cboxOverlay,
#cboxWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: hidden
}

#cboxWrapper {
    max-width: none
}

#cboxOverlay {
    position: fixed;
    width: 100%;
    height: 100%
}

#cboxMiddleLeft,
#cboxBottomLeft {
    clear: left
}

#cboxContent {
    position: relative
}

#cboxLoadedContent {
    overflow: auto;
    -webkit-overflow-scrolling: touch
}

#cboxTitle {
    margin: 0
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
    cursor: pointer
}

.cboxPhoto {
    float: left;
    margin: auto;
    border: 0;
    display: block;
    max-width: none;
    -ms-interpolation-mode: bicubic
}

.cboxIframe {
    width: 100%;
    height: 100%;
    display: block;
    border: 0;
    padding: 0;
    margin: 0
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box
}

#cboxOverlay {
    background: #000
}

#colorbox {
    outline: 0
}

#cboxContent {
    margin-top: 20px;
    background: #000
}

.cboxIframe {
    background: #fff
}

#cboxError {
    padding: 50px;
    border: 1px solid #ccc
}

#cboxLoadedContent {
    border: 5px solid #000;
    background: #fff
}

#cboxTitle {
    position: absolute;
    top: -20px;
    left: 0;
    color: #ccc
}

#cboxCurrent {
    position: absolute;
    top: -20px;
    right: 0;
    color: #ccc
}

#cboxLoadingGraphic {
    background: url('/assets/js/vendor/jquery.colorbox/images/loading.gif') no-repeat center center
}

#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
    border: 0;
    padding: 0;
    margin: 0;
    overflow: visible;
    width: auto;
    background: none
}

#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
    outline: 0
}

#cboxSlideshow {
    position: absolute;
    top: -20px;
    right: 90px;
    color: #ccc
}

#cboxPrevious {
    position: absolute;
    top: 50%;
    left: 5px;
    margin-top: -32px;
    background: url('/assets/js/vendor/jquery.colorbox/images/controls.png') no-repeat top left;
    width: 28px;
    height: 65px;
    text-indent: -9999px
}

#cboxPrevious:hover {
    background-position: bottom left
}

#cboxNext {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -32px;
    background: url('/assets/js/vendor/jquery.colorbox/images/controls.png') no-repeat top right;
    width: 28px;
    height: 65px;
    text-indent: -9999px
}

#cboxNext:hover {
    background-position: bottom right
}

#cboxClose {
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
    background: url('/assets/js/vendor/jquery.colorbox/images/controls.png') no-repeat top center;
    width: 38px;
    height: 19px;
    text-indent: -9999px
}

#cboxClose:hover {
    background-position: bottom center
}

#colorbox img {
    max-width: 3000px
}

.descr_block {
    margin-left: 10%
}

.block-avto {
    background: url('/assets/images/bg-avto.png') repeat;
    border: 1px solid #DADADA;
    padding: 20px 10px;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.18)
}

.sitemap,
.sitemap ul {
    list-style: none
}

.sitemap li {
    background: none
}

.sitemap1 {
    font-size: 1.3em;
    line-height: 1.8em
}

.gbook {
    background: #f3f3f3
}

.gbook .gbook_head {
    font-weight: 700
}

dl.news-block {
    padding: 0 0 20px;
    clear: both
}

dl.news-block a,
dl.news-block a:visited {
    color: #000
}

dl.news-block a:hover {
    color: #c00
}

dl.news-block dt {
    padding: 0 0 7px;
    font-weight: 400
}

dl.news-block .news-date {
    display: block;
    padding: 0 5px 0 0;
    color: #666;
    font-size: 8pt
}

dl.news-block img {
    margin: 0 15px 5px 0
}

dl.news-block dd {
    color: #000;
    text-decoration: none
}

dl.news-block:after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    height: 0
}

div.news-hr {
    margin: 3em 0 0;
    padding: 0 0 3em;
    border-top: 1px solid #000;
    clear: both
}

div.news_years {
    margin: 0 0 .9em
}

div.news_years span {
    padding: 3px;
    background: #ccc
}

ol.search_results li {
    margin-bottom: 12pt
}

ol.search_results span {
    font-size: 8pt
}

table.mod_opros_tbl td {
    padding: 0 60px 60px 0;
    text-align: left;
    vertical-align: top
}

table.mod_opros_tbl .opros_head {
    font-weight: 700
}

table.tbl_prod {
    margin: 0 0 1em;
    width: 100%
}

table.tbl_prod td {
    padding: 20px 10px;
    border-bottom: 1px solid #ccc
}

table.tbl_prod td.td_center {
    text-align: center
}

table.tbl_prod tr.prod_head td,
table.tbl_prod td.prod_head {
    padding: 10px;
    color: #fff;
    background: #00589b;
    white-space: nowrap
}

table.tbl_prod tr.prod_head a,
table.tbl_prod td.prod_head a {
    color: #fff
}

table.tbl_prod tr.prod_head td img {
    margin: 0 5px
}

table.tbl_prod td.prod_img {
    width: 150px
}

table.tbl_prod td.prod_col input.bas_add_add {
    position: relative;
    top: 2px;
    left: 3px
}

table.tbl_prod td.prod_col input.bas_add_col {
    text-align: center;
    width: 50px
}

table.tbl_prod .in_basket,
table.tbl_prod2 .in_basket {
    font-size: 7pt
}

table.tbl_prod2 {
    width: 100%
}

table.tbl_prod2 td {
    padding: 5px 10px;
    text-align: left;
    vertical-align: top
}

table.tbl_prod2 td.prod2_txt img.prod2_img {
    margin: 15px;
    float: right
}

table.tbl_prod2 td.prod2_txt {
    width: 100%
}

table.tbl_prod2 td.prod2_txt div.prod2_name {
    padding: 0 0 15px
}

table.tbl_prod2 td.prod2_txt div.prod2_price {
    padding: 0 0 15px
}

table.tbl_prod2 td.prod2_txt div.prod2_col {
    padding: 0 0 15px
}

table.tbl_prod2 td.prod2_txt div.prod2_col input.bas_add_add {
    position: relative;
    top: 2px;
    left: 3px
}

table.tbl_prod2 td.prod2_txt div.prod2_col input.bas_add_col {
    width: 50px;
    text-align: center
}

table.tbl_prod2 td.prod2_txt div.prod2_descr {
    padding: 15px 0 0
}

.basket_img {
    max-height: 70px
}

.form-row {
    margin: 0 0 1em
}

.form-row-required .form-label:before {
    content: '*';
    color: red
}

.inp {
    border: 1px solid #818181;
    border-radius: 2px;
    color: #3e3e3e;
    font-size: 16px;
    font-family: var(--typography__default);
    padding: 7px 11px;
    outline: 0;
    min-width: 300px;
    margin-bottom: 5px
}

.inp:active,
.inp:focus {
    border: 1px solid #24a2c1
}

label {
    font-size: 12px;
    color: #000;
    line-height: 1.8em
}

.inp_but {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    background-color: #ed8636;
    border-radius: 3px;
    padding: 5px 15px;
    color: #fff;
    font-size: 18px;
    opacity: .8;
    border: none
}

.inp_but:hover {
    opacity: 1
}

.inp_but,
button,
.img_captcha {
    cursor: pointer
}

.fail_input {
    color: #fc2114;
    font-size: 12px
}

.news_years {
    margin: 15px 0 0;
    float: left;
    width: 100%
}

.page_nav {
    margin: 0 0 30px;
    float: left;
    width: 100%
}

.page_nav span {
    padding: 1px 4px;
    font-weight: 400;
    background: #ccc
}

.page_sort {
    margin: 0 0 .9em;
    text-align: right
}

.footer_container {
    width: 960px;
    height: 120px;
    margin: 0 auto;
    overflow: hidden
}

.footer_bg {
    background-color: #333;
    width: 1280px;
    margin-left: -160px;
    padding: 15px 0;
    height: 115px;
    position: absolute
}

.footer {
    margin: 0 auto;
    max-width: 940px;
    min-width: 639px;
    padding: 0 10px;
    height: 100%
}

.footer p,
.footer a {
    color: #d5d5d5;
    font-size: 12px
}

.footer p {
    margin: 7px 0
}

.citation {
    border-left: 5px solid #fe6f00;
    padding: 25px 35px;
    background-color: #e6e6e6;
    border-top: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5
}

.text_block_50 {
    float: left;
    width: 46%;
    padding: 0 2% 0 0;
    display: block
}

.text_block_33 {
    float: left;
    width: 29%;
    padding: 0 2% 0 0;
    display: block
}

.left_footer_block {
    width: 22%;
    float: left;
    padding: 25px 1% 1%;
    text-align: right
}

.center_footer_block {
    float: left;
    width: 45%
}

.right_footer_block {
    width: 22%;
    float: right;
    text-align: right;
    padding: 22px 1% 1%
}

.fp_overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: #000;
    opacity: .6;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=60);
    z-index: 1000
}

.popup_window_call_order,
.popup_window_order_car,
.popup_window_message {
    background-color: #fff;
    padding: 50px 65px 45px;
    position: absolute;
    top: 15%;
    left: 38%;
    z-index: 9999;
    display: none;
    font-size: 20px;
    min-height: 200px;
    -webkit-box-shadow: 0 5px 15px #000;
    -moz-box-shadow: 0 5px 15px #000;
    box-shadow: 0 3px 14px #000
}

.popup_window_call_order {
    top: 20%;
    left: 38%
}

#close_window {
    width: 15px;
    height: 15px;
    background: url('/assets/images/close_popup.jpg') no-repeat 0 0;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer
}

input.orange_button {
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    padding: 11px 15px 10px;
    color: #FFF;
    font-size: 14px;
    background: linear-gradient(to bottom, #62E41F 0, #1A9A0A 100%);
    text-transform: uppercase;
    opacity: 1;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5)
}

input.orange_button:hover,
.orange_button:hover {
    opacity: .9
}

.orange_button {
    font-size: 18px;
    cursor: pointer;
    padding: 12px 20px;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    color: #FFF;
    border: none;
    position: relative;
    background: linear-gradient(to bottom, #FFCB00 0, #F77400 100%);
    text-transform: uppercase;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.78)
}

.table {
    width: 100%
}

.table td {
    color: #000;
    background: #fff;
    border-bottom: 1px solid #c4c4c4;
    padding: 7px 15px;
    font-family: var(--typography__default);
    font-size: 14px
}

.table .table_head {
    background: #fe6f00;
    padding: 7px 15px;
    font-family: var(--typography__default);
    font-size: 14px;
    font-weight: 700;
    border-bottom: none;
    color: #fff
}

a:focus {
    outline: 0
}

body {
    background: url('/assets/images/bg.png') repeat
}

body,
td,
select,
input,
textarea {
    color: #131313;
    //font: 16px/1.3em Trebuchet MS, sans-serif
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    font-family: var(--typography__default);
}

a {
    color: #208fca;
    text-decoration: none
}

a:hover {
    color: #fe6f00
}

.breadcrumbs {
    margin: -19px 0 15px 2px;
    padding: 0;
    font-size: 12px;
    color: #878787
}

.breadcrumbs a {
    color: #878787
}

.breadcrumbs a:hover {
    text-decoration: underline
}

.breadcrumbs li {
    display: inline-block;
    margin: 0;
    list-style: none
}

.breadcrumbs li:before {
    display: inline-block;
    margin: 0 3px;
    content: ' / '
}

.breadcrumbs li:first-child:before {
    margin: 0;
    content: ''
}

.top_menu_container {
    width: 1280px;
    min-width: 320px;
    height: 65px;
    position: fixed;
    background-color: #ffcb00;
    top: 0;
    -webkit-box-shadow: 0 1px 8px #000;
    -moz-box-shadow: 0 1px 8px #000;
    box-shadow: 0 1px 8px #000;
    top: 0;
    z-index: 500;
    margin-left: -160px
}

.top_menu_block {
    margin: 0 auto;
    max-width: 940px;
    padding: 0 10px;
    height: 100%;
    position: relative
}

.top_menu_block .top_menu_left {
    margin-top: 4px;
    float: left;
    position: relative;
    height: 56px;
    width: 565px
}

.top_menu_block .top_menu_right {
    float: right;
    width: 370px;
    height: 65px
}

.top_menu_left .top_logo {
    margin-right: 15px;
    width: 170px;
    height: 100%;
    float: left;
    display: block;
    position: relative
}

.top_menu_left .top_logo .name1 {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    font-family: var(--typography__default);
    position: absolute;
    top: 12px
}

.top_menu_left .top_logo .name2 {
    color: #131313;
    font-size: 12px;
    position: absolute;
    left: 0;
    bottom: 4px;
    font-style: italic
}

.footer .footer_logo {
    width: 220px;
    height: 100%;
    float: left;
    display: block;
    position: relative
}

.footer .footer_logo .name1 {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    font-family: var(--typography__default);
}

.footer .footer_logo .name2 {
    color: #fff;
    font-size: 14px;
    font-family: var(--typography__default);
}

.top_menu_left .top_menu {
    margin: 2px 0 0;
    padding: 0;
    list-style-type: none;
    float: left;
    width: 360px
}

.top_menu_left .top_menu li {
    margin: 0;
    padding: 0;
    float: left;
    position: relative
}

.top_menu_left .top_menu li a {
    color: #000;
    font-size: 14px;
    padding: 17px 8px 18px;
    display: inline-block;
    text-decoration: underline;
    font-weight: 400;
    font-style: italic
}

.top_menu_left .top_menu li a:hover {
    text-decoration: underline
}

.top_menu_left .top_menu li a.active {
    color: #000;
    text-decoration: none
}

.top_menu .submenu {
    margin: 3px 0 0;
    padding: 0;
    float: left;
    list-style-type: none;
    position: absolute;
    background-color: #ffd840;
    width: 300px;
    display: none;
    box-shadow: -1px 13px 6px -12px #000 inset;
    top: 56px
}

.top_menu .submenu li {
    width: 100%
}

.top_menu .submenu li a {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
    border-bottom: 1px solid #ccad33;
    width: 270px;
    padding: 10px 15px
}

.top_menu .submenu li a:hover {
    text-decoration: none;
    color: #000
}

.top_menu_right .top_call_order_button {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    margin-top: 4px;
    background-color: #fff;
    border-radius: 3px;
    padding: 5px 15px;
    color: #FFF;
    font-size: 12px;
    border: none;
    float: right;
    position: relative;
    top: 14px;
    background: linear-gradient(to bottom, #62E41F 0, #1A9A0A 100%);
    text-transform: uppercase;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5)
}

.prod_item {
    background: url('/assets/images/bg-avto.png') repeat;
    border: 1px solid #DADADA;
    padding: 20px 10px;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.18)
}

.top_menu_right .top_call_order_button:hover {
    opacity: .8
}

.top_menu_right .top_edit_adress {
    width: 210px;
    text-align: right;
    margin: 18px 0 0;
    line-height: 1em
}

.footer-bg {
    float: left;
    width: 100%
}

.top_menu_right .top_edit_adress p {
    margin: 0
}

.header-text {
    font-size: 21px
}

.content_container {
    min-height: 200px;
    margin: 0 auto;
    position: relative;
    max-width: 940px;
    padding: 0 10px 50px;
    min-width: 300px
}

.content {
    width: 100%;
    float: left
}

.left_menu {
    display: none;
    float: left;
    width: 22%;
    margin: 0
}

.left_menu li {
    list-style-type: disc
}

.left_menu li,
.left_menu a {
    color: #585858
}

.left_menu li:hover,
.left_menu a:hover {
    color: #000
}

.left_menu a {
    display: block;
    margin: 0 0 17px;
    font-size: 14px
}

.content_inner {
    width: 100%
}

.content_inner_right {
    width: 73%;
    float: right;
    margin: 0 auto
}

.start_content {
    margin: 0 auto;
    position: relative;
    max-width: 940px;
    padding: 14px 10px 200px;
    min-width: 300px
}

.start_pre_block {
    width: 940px;
    margin: 0 auto;
    padding: 0 10px;
}

.start_post_block {
    width: 940px;
    margin: 0 auto;
    padding: 0 10px;
}

.start_items_container {
    float: left;
    width: 1280px;
    padding: 25px 0 15px;
    margin-left: -170px
}

.start_item_block {
    margin: 0 auto;
    width: 940px;
    padding: 0 10px
}

.start_item_block .start_item_img {
    float: left;
    width: 50%;
    text-align: center
}

.start_item_block .start_item_img img {
    max-width: 65%;
    border: 5px solid #fff;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.18)
}

.tel-text {
    color: #3e3d3d;
    font-size: 12px
}

.start_item_block .start_item_descr_block {
    float: right;
    width: 50%
}

.start_item_block .start_item_descr_block .start_item_name {
    font-size: 28px;
    font-weight: 700;
    margin: 10px 0 25px;
    text-transform: uppercase;
    line-height: 1.3em;
}

.start_item_block .start_item_descr_block .start_item_descr {
    font-size: 14px
}

.start_item_block .start_item_descr_block .start_item_descr p {
    margin: 2px 0
}

.card_item_block {
    margin: 0 auto;
    width: 940px;
    padding: 0 10px
}

.card_item_block .card_item_img {
    float: left;
    width: 460px
}

.card_item_block .card_item_descr_block {
    float: right;
    width: 410px
}

.card_item_block .card_item_descr_block .card_item_name {
    font-size: 36px;
    font-weight: 700;
    margin: 25px 0 40px;
    text-transform: uppercase
}

.card_item_block .card_item_descr_block .card_item_descr {
    font-size: 18px
}

.card_item_block .card_item_descr_block .card_item_descr p {
    margin: 11px 0
}

.map_yandex_container {
    width: 1278px;
    float: left;
    height: 450px;
    margin-left: 0px
}

.map_yandex {
    border: 1px solid #999;
    width: 1278px;
    height: 450px;
    position: relative;
    margin: 0 auto
}

.catalog_container {
    text-align: center;
    width: 100%;
    float: left;
    margin-bottom: 40px
}

.prod_item {
    width: 290px;
    margin: 10px;
    display: inline-block
}

.prod_item:hover {
    opacity: .8
}

.prod_item .prod_img {
    text-align: center;
    width: 100%;
    height: 215px
}

.prod_img img {
    border: 5px solid #fff;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.18)
}

.card_item_img a img {
    border: 5px solid #fff;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.18)
}

.prod_item .prod_name {
    text-align: center;
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 1em;
    overflow: hidden
}

.min_top_menu {
    display: none
}

.min_top_menu .submenu {
    display: none
}

#body_bg {
    // height: 100%;
    width: 1280px;
    margin: 0 auto
}

.wrapper {
    max-width: 960px;
    min-width: 320px;
    margin: 0 auto;
    min-height: 100%
}

.top-mobile-phone {
    display: none;
}

.map_google_container {
    width: 1278px;
    float: left;
    height: 450px;
    margin-left: -160px
}

.map_google {
    border: 1px solid #999;
    width: 1278px;
    height: 450px;
    position: relative;
    margin: 0 auto
}

.map_google_responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.map_google_responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

#map {
    width: 100%;
    height: 100%;
}